import React, { useEffect, useRef } from "react";
import gsap from "gsap";

const Cursor = () => {
  const cursorRef = useRef(null);

  useEffect(() => {
    const cursor = cursorRef.current;

    const handleMouseMove = (e) => {
      const { clientX: mouseX, clientY: mouseY } = e;

      // Actualiza las posiciones del cursor con GSAP
      gsap.to(cursor, {
        x: mouseX,
        y: mouseY,
        duration: 0.1, // Duración corta para evitar vibraciones
        ease: "power2.out",
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return <div className="cursor" ref={cursorRef}></div>;
};

export default Cursor;
